// import React, { useState } from 'react'
// import {Table, Space,Button } from 'antd'
// import styled from 'styled-components'
// import {Link} from 'react-router-dom'
// import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa';
// import {useDispatch} from 'react-redux'
// import {createOrderPdf} from '../../../api/couponSlice'
// import { Descriptions } from 'antd';
// import moment from 'moment'
// export default function Datatable({data,loading}) {


//     // const [loading, setLoading] = useState(false)
//     const dispatch = useDispatch()
//     const [loadings,setLoading] =useState(false)
//     const [item,setItem] =useState(null)


// const createPdf= (value)=> {

//         dispatch(createOrderPdf(value))
//         setLoading(true)
//         setItem(value._id)
//         setTimeout(()=>{
//            setLoading(false)
//             setItem(null)
//         },3000)

// }
//     const columns = [
//         {
//             title:"Name",
//             render: (value)=>{

//                 if(value.user){

//                 return <p>{value.user.email}</p>
                  
//                 }
                
//                if(value.existUser) {
                    
//                   return   <p>{value.existUser.email}</p>

//                 }

//                 // value && value.user ? value.user.email : value.existUser.email
//                 return null
//             }
            
//         },
//         {
//             title:"Order ID",
//             dataIndex:"sycOrderId",

//         },

//         {
//             title:"Razorpay Order ID",
//             dataIndex:"orderId",

//         },
//         {
//             title:"Date",
//             dataIndex:"createdAt",
//             render: (value)=>{
               
//                 return <p>{moment(value.createdAt).format('DD/MM/YYYY')}</p>
//             }

//         },
        
//         {
//             title:'Invoice',
//             render: (value)=>  
//             <Button type="primary" onClick={()=>createPdf(value)}>
//              {(loadings && item === value._id) ? 'Loading': 'Download'}
//             </Button>
//         }
//     ]

//     return (
//         <SDataModelWrap>
//              <Table
//                  loading={loading}
//                  dataSource={data}
//             columns={columns}
//             style={{overflowY:'auto', height:'79vh', overflowX:'hidden' }}

//             rowKey = {(record => record._id)}
//             expandable={{
//                 expandedRowRender: record => {
                
                   
//                     return <div >   
                
    
  
//       <div class=" text-left" >
//       <div class="row">
//       <div class="col-2 ">
//       <p><b className="pl-4">Sl.No</b></p>
//       </div>
//       <div class="col-2">
//       <p><b className="">Item</b></p>
//       </div>
//       <div class="col-2">
//       <p className=""><b>Quantity</b></p>
//       </div>
//       <div class="col-2">
//       <p className=""><b>Unit Price</b></p>
//       </div>
//       <div class="col-2">
//       <p className=""><b>Total Price : {record.total }</b></p>
//       </div>
//       </div>
//       </div>
      
//       {
//           record?.scouponsOrder?.map((item,i)=>{
//            return <div key={i} class=" text-left">
//             <div class="row">
//             <div class="col-2">
//             <p className=" pl-4">{i+1}</p>
//             </div>
//             <div class="col-2">
//             <p className="">{item.name}</p>
//             </div>
//             <div class="col-2">
//             <p className="">{item.quantity}</p>
//             </div>
//             <div class="col-2">
//             <p className="">{item.value }</p>
//             </div>
          
//             </div>
//             </div>


//           })
//       }

//   </div>    
  
  
//                 },
//                 rowExpandable: record => record.user  !== null,
//               }}
         

//             /> 
         
//         </SDataModelWrap>
//     )
// }


// const SDataModelWrap = styled.div`

// svg{

//     font-size:1.2rem;
//     text-align:center;
// }
// .ant-descriptions-title{

// padding-left:2rem;
// font-size:1rem;
// color:grey;

// }
// `


// import React, { useState } from "react";
// import { Table, Space, Image, Button, Modal } from "antd";
// import styled from "styled-components";
// import { Link } from "react-router-dom";
// import { FaRegTrashAlt, FaRegEdit } from "react-icons/fa";
// import { useDispatch } from "react-redux";
// import DeleteConfirm from "../../shared/deleteConfirm";
// import moment from "moment";
// import { deleteTemplate } from "../../api/template";
// import Templatedownload from "./templatepint"

// export default function Datatable({ data, loading }) {
//   const dispatch = useDispatch();
//   const [item, setItem] = useState(null); // To store the clicked row data
//   const [isModalOpen, setIsModalOpen] = useState(false); // To control modal visibility

//   // Function to open modal with specific record's data
//   const showModal = (record) => {
//     setItem(record);  // Set the clicked row data into the state
//     setIsModalOpen(true);  // Open the modal
//   };

//   // Handle the "OK" button of the modal
//   const handleOk = () => {
//     setIsModalOpen(false);  // Close the modal
//   };

//   // Handle the "Cancel" button of the modal
//   const handleCancel = () => {
//     setIsModalOpen(false);  // Close the modal
//   };

//   const confirm = (e, id) => {
//     dispatch(deleteTemplate(id));  // Delete template by ID
//   };

//   const columns = [
//     {
//       title: "Username",
//       dataIndex: "user_name",
//       render: (name) => <h6>{name}</h6>,
//     },
//     {
//       title: "Gift Code",
//       dataIndex: "gift_code",
//       render: (name) => <h6>{name}</h6>,
//     },
//     {
//       title: "CVV",
//       dataIndex: "cvv",
//       render: (name) => <h6>{name}</h6>,
//     },
//     {
//       title: "Value",
//       dataIndex: "value",
//       render: (name) => <h6>{name}</h6>,
//     },
//     {
//       title: "Logo",
//       dataIndex: "logo",
//       render: (imag) => (
//         <Image
//           preview={false}
//           style={{
//             margin: "0px",
//             width: "50px",
//             borderRadius: "0.25rem",
//             display: "block",
//             objectFit: "cover",
//           }}
//           src={imag}
//           className="bg-dark"
//         />
//       ),
//     },
//     {
//       title: "Expiry Date",
//       dataIndex: "expiry_date",
//       render: (expiry_date) => <h6>{moment(expiry_date).format("MMM DD YYYY")}</h6>,
//     },
//     {
//       title: "Action",
//       render: (record) => (
//         <Space size="middle">
//           <h5>
//             <Link to={`/admin/template/${record._id}`}>
//               <FaRegEdit />
//             </Link>
//           </h5>
//           <h5 className="text-danger">
//             <DeleteConfirm confirm={(e) => confirm(e, record._id)} title="blog">
//               <FaRegTrashAlt style={{ cursor: "pointer" }} />
//             </DeleteConfirm>
//           </h5>
//           {/* Button to open modal for the specific record */}
//           <Button type="primary" onClick={() => showModal(record)}>
//             View Details
//           </Button>
//         </Space>
//       ),
//     },
//   ];

//   return (
//     <>
//       <SDataModelWrap>
//         <Table
//           loading={loading}
//           dataSource={data}
//           columns={columns}
//           style={{ overflowY: "auto", height: "77vh", overflowX: "hidden" }}
//           rowKey={(record) => record._id}
//         />
//       </SDataModelWrap>

//       {/* Modal Component */}
//       <Modal
//         title="Template Details"
//         visible={isModalOpen}  // Control visibility using state
//         onOk={handleOk}  // Handle "OK" button click
//         onCancel={handleCancel}
//         footer={null} 
//         width={1000}
//         bodyStyle={{ maxHeight: '400px', overflowY: 'auto' }} // Handle "Cancel" button click
//       >
//         {/* Render the details of the selected row */}
//         {item && (
//           <>
          
//             <Templatedownload data={item}/>
            
//           </>
//         )}
//       </Modal>
//     </>
//   );
// }

// const SDataModelWrap = styled.div`
//   svg {
//     font-size: 1.2rem;
//     text-align: center;
//     width:auto
//   }
// `;

import React, { useState } from 'react';
import { Table, Space, Button, Modal } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { createOrderPdf } from '../../../api/couponSlice';
import moment from 'moment';
import InvoicePrint from "../couponOrder/invoiceprint"

export default function Datatable({ data, loading }) {
  const dispatch = useDispatch();
  const [loadings, setLoading] = useState(false);
  const [item, setItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalItem, setModalItem] = useState(null);

  const createPdf = (value) => {
    dispatch(createOrderPdf(value));
    setLoading(true);
    setItem(value._id);
    setTimeout(() => {
      setLoading(false);
      setItem(null);
    }, 3000);
  };

  const showModal = (record) => {
    setModalItem(record); // Set the clicked row data
    setIsModalOpen(true); // Open the modal
  };

  const handleOk = () => {
    setIsModalOpen(false); // Close the modal
  };

  const handleCancel = () => {
    setIsModalOpen(false); // Close the modal
  };

  const columns = [
    {
      title: 'Name',
      render: (value) => {
        if (value.user) {
          return <p>{value.user.email}</p>;
        }
        if (value.existUser) {
          return <p>{value.existUser.email}</p>;
        }
        return null;
      },
    },
    {
      title: 'Order ID',
      dataIndex: 'sycOrderId',
    },
    {
      title: 'Razorpay Order ID',
      dataIndex: 'orderId',
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      render: (value) => {
        return <p>{moment(value).format('DD/MM/YYYY')}</p>;
      },
    },
    {
      title: 'Invoice',
      render: (value) => (
        <Space>
          {/* <Button type="primary" onClick={() => createPdf(value)}>
            {loadings && item === value._id ? 'Loading' : 'Download'}
          </Button> */}
          <Button type="default" onClick={() => showModal(value)}>
            View Invoice
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <SDataModelWrap>
      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        style={{ overflowY: 'auto', height: '79vh', overflowX: 'hidden' }}
        rowKey={(record) => record._id}
        expandable={{
          expandedRowRender: (record) => {
            return (
              <div>
                <div className="text-left">
                  <div className="row">
                    <div className="col-2">
                      <p>
                        <b className="pl-4">Sl.No</b>
                      </p>
                    </div>
                    <div className="col-2">
                      <p>
                        <b className="">Item</b>
                      </p>
                    </div>
                    <div className="col-2">
                      <p>
                        <b>Quantity</b>
                      </p>
                    </div>
                    <div className="col-2">
                      <p>
                        <b>Unit Price</b>
                      </p>
                    </div>
                    <div className="col-2">
                      <p>
                        <b>Total Price : {record.total}</b>
                      </p>
                    </div>
                    
                  </div>
                </div>

                {record?.scouponsOrder?.map((item, i) => {
                  return (
                    <div key={i} className="text-left">
                      <div className="row">
                        <div className="col-2">
                          <p className="pl-4">{i + 1}</p>
                        </div>
                        <div className="col-2">
                          <p className="">{item.name}</p>
                        </div>
                        <div className="col-2">
                          <p className="">{item.quantity}</p>
                        </div>
                        <div className="col-2">
                          <p className="">{item.value}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          },
          rowExpandable: (record) => record.user !== null,
        }}
      />

      {/* Modal Component */}
      <Modal
        title="Order Details"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={1000}
        bodyStyle={{ maxHeight: '400px', overflowY: 'auto' }}
      >
        {modalItem && (
          <div>
            {/* <h3>Order Information</h3> */}
         
            <InvoicePrint data={modalItem}/>
          </div>
        )}
      </Modal>
    </SDataModelWrap>
  );
}

const SDataModelWrap = styled.div`
  svg {
    font-size: 1.2rem;
    text-align: center;
  }
  .ant-descriptions-title {
    padding-left: 2rem;
    font-size: 1rem;
    color: grey;
  }
`;
