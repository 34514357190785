// import React, { useRef } from "react";
// import { useReactToPrint } from "react-to-print";

// const PrintComponent = React.forwardRef(({ data }, ref) => (
//   <div ref={ref}>
//     <h1>Printable Content</h1>
//     <p>Full Name: {data?.personal_details?.full_name || "No Data"}</p>
//     <p>Email: {data?.personal_details?.email || "No Data"}</p>
//   </div>
// ));

// const TemplatePrint = ({ data }) => {
//   const componentRef = useRef();
//  console.log(componentRef.current)
//   const handlePrint = useReactToPrint({
//     content: () => {
//       console.log("inside it is",componentRef.current); // Confirm ref is set
//       return componentRef.current;
//     },
//     documentTitle: data?.personal_details?.full_name || "default",
//     onBeforePrint: () => console.log("Before printing...", "the content is",componentRef.current),
//     onAfterPrint: () => console.log("After printing..."),
//   });

//   return (
//     <div>
//       <button
//         onClick={handlePrint}
//         className="ml-[84%] bg-[#43468B] text-white text-center flex border px-4 py-2 rounded-md mb-2"
//       >
//         Download PDF
//       </button>
//       <div ref={componentRef}>
//         <PrintComponent data={data} />
//       </div>
//     </div>
//   );
// };

// export default TemplatePrint;

// --------------------------------second trail-----------------------------------
// import React, { useRef } from "react";
// import { useReactToPrint } from "react-to-print";


// const PrintComponent = React.forwardRef(({ data }, ref) => (
//   <div ref={ref}>
//     <h1>Printable Content</h1>
//     <p>Full Name: {data?.personal_details?.full_name || "No Data"}</p>
//     <p>Email: {data?.personal_details?.email || "No Data"}</p>
//   </div>
// ));

// const TemplatePrint = ({ data }) => {
//   const componentRef = useRef();

 
//   const handlePrint = useReactToPrint({
//     contentRef: () => componentRef.current,
//     documentTitle: data?.personal_details?.full_name || "default",
//     onBeforePrint: () => console.log("before printing..."),
//     onAfterPrint: () => console.log("after printing..."),
//     removeAfterPrint: true,
//   });

//   const handleButtonClick = () => {
//     console.log("Button clicked");
//     console.log("componentRef.current:", componentRef.current); 
//     if (componentRef.current) {
//       handlePrint();  
//     } else {
//       console.log("No content to print, ref is null or undefined.");
//     }
//   };

//   return (
//     <div>
//       <button
//         onClick={handleButtonClick}
//         className="ml-[84%] bg-[#43468B] text-white text-center flex border px-4 py-2 rounded-md mb-2"
//       >
//         Download PDF
//       </button>

//       <div ref={componentRef}>
//         <PrintComponent data={data} />
//       </div>
//     </div>
//   );
// };

// export default TemplatePrint;



// ---------------------------------------third trail----------------------------------------
import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Button } from "antd"; // AntD Button component
import Banner from "../../shared/images/logo2.png"

// PrintComponent to be printed
const PrintComponent = React.forwardRef(({ data }, ref) => (
  <div ref={ref} style={{ fontFamily: "Poppins, sans-serif", fontSize: "8px", letterSpacing: "1px" }}>
    <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Rajdhani&family=Rajdhani&display=swap');

          @font-face {
            font-family: SourceSansPro;
            src: url(SourceSansPro-Regular.ttf);
          }
          
          .clearfix:after {
            content: "";
            display: table;
            clear: both;
          }

          body {
            position: relative;
            width: 16cm;  
            height: 20cm; 
            margin: 0 auto; 
            color: #000000;
            background: #FFFFFF; 
            font-family: 'Poppins', sans-serif; 
            font-size: 8px; 
            letter-spacing: 1px;
            font-style: normal;
          }
          
          .back{
            background-image: url("https://firebasestorage.googleapis.com/v0/b/sycamore-admin-6ca26.appspot.com/o/images%2Fimage1.png?alt=media&token=8b8324c3-3d65-41f5-87c7-c5e04e2f90d9");
            background-repeat: no-repeat;
            background-size: 150px ;
            background-position: center top; 
          }
          
          .back1{
            background-image: url("https://firebasestorage.googleapis.com/v0/b/sycamore-admin-6ca26.appspot.com/o/images%2Fimage3.png?alt=media&token=e94181d3-263c-4935-ba56-ab152ce854c9");
            background-repeat: no-repeat;
            background-size: 120px ;
            background-position: left bottom; 
            height: 6cm;
          }

          a {
            color: rgb(0, 0, 0);
            text-decoration: none;
          }

          header {
            width:100%;
            height:5cm;
             text-align: center;
          }

          #header img {
            width: 25cm; 
            height:10cm; 
          }

          #details {
            margin-top:10px;
            margin-bottom: 10px;
            padding:0 20px;
            font-size: 1.45em;
          }

          #details1 {
            margin-top:0px;
            margin-bottom: 20px;
            margin-left:20px;
            margin-right:20px
          }

          #coupon {
            float: left;
            font-weight: bold;
            color: #000000;
          }

          #coupon .gift {
            color: #000000;
          }

          #coupon .code {
            border: 1px solid gray;
            border-radius: 20px;
            padding:3px 10px;
            margin: 15px 0 15px 0 ;
            color: #000000;
            font-family: 'Poppins', sans-serif;
          }

          #company img {
            width: 3.6cm; 
            height:1.2cm;
          }

          #right {
            float: right;
            margin: 10px 5px 0 0;
            color: #000000;
          }

          #right .cvv {
            float: left;
            text-align: left;
            font-family: SourceSansPro;
            font-style: normal;
          }

          #right h1 {
            font-size: 1.1em;
            font-weight: normal;
            padding: 5px 0;
          }

          #messagediv {
            margin-top:15px;
            margin-bottom: 20px;
            padding:0 18px;
            font-size: 1.25em;
            color: #000000;
          }

          #text {
            border: 1px solid rgb(0, 0, 0);
            padding:18px 18px;
            float: left;
            font-weight: normal;
            width:7.5cm;
            color: #000000;
            margin-top:18px;
          }

          #text .name {
            padding-bottom: 12px;
            font-size: 1.2em;
            font-family: 'Poppins', sans-serif;
          }

          #text .message {
            padding-bottom: 20px;
            font-size: 1.2em;
            color: #000000;
            font-family: 'Poppins', sans-serif;
          }

          #text .team {
            font-size: 0.9em;
            float: right;
            letter-spacing: 0px;
            color: #000000;
            font-family: 'Poppins', sans-serif;
          }

          #rightcoupon {
            float: right;
            margin: 3px 5px 0 0;
            text-align: center;
          }

          #rightcoupon .amount {
            float:none;
            text-align: none;
          }

          #rightcoupon .value {
            font-size: 2.5rem;
            color: rgb(226, 19, 19);
            font-weight: bolder;
          }

          #gift img {
            width: 5.5cm; 
          }

          #rightcoupon .link {
            font-size: 0.9em;
            font-weight: normal;
            border: 1px solid gray;
            padding:3px 10px;
            margin-top: 7px;
          }

          .link img{
            width: 1.4em;
            margin-left:8px;
          }

          .link a{
            display: flex;
            align-items: center;
          }

          .footer {
            padding: 8px 18px 0 23px;
            margin-top: 2px;
          }

          #logo {
            float: left;
            width: 6.0cm;
          }

          #logo img {
            width: 3.3cm; 
          }

          #instruction a {
            text-decoration: none;
            font-style: normal;
          } 

          #logo .instructionTitle{
            font-size: 1.8em;
            font-weight: bold;
            margin-left: 23px;
            margin-top: 5px;
            color: #000000;
            font-style: normal;
          }

          #logo a{
            color: red;
            text-decoration: none;
          }

          #logo li{
            font-size: 1.1em;
            font-weight: normal;
            padding: 5px 0;
            font-style: normal;
            color: #000000;
          }

          .back2{
            background-image: url("https://firebasestorage.googleapis.com/v0/b/sycamore-admin-6ca26.appspot.com/o/images%2Fimage2.png?alt=media&token=e2979102-4a4e-40fb-986f-81c2ab77b426");
            background-repeat: no-repeat;
            background-size: 150px ;
            background-position: right bottom;
            float: right;
            height: 6.5cm;
            margin-right: 35px;
          }

          #contact {
            border: 1px solid #88448A;
            padding:10px 20px;
            margin-top: 60px;
            font-style: normal;
          }

          #contact .title{
            font-size: 1.3em;
            font-weight: bold;
            margin-bottom: 15px;
            color: #000000;
            letter-spacing: 0px;
          }

          #contact .call{
            font-size: 1.05em;
            font-weight: normal;
            padding-left: 20px;
          }

          #contact .mail{
            font-size: 1.05em;
            font-weight: normal;
            padding: 8px 0 5px 0;
            padding-left: 20px;
          }

          #contact .mail2{
            font-size: 1.05em;
            font-weight: normal;
            padding-left: 57px;
            margin-left:9px
          }
        `}
      </style>
     <div id="details1">
      <div id="header" >
          <img src={data.banner} className=""/>
      </div>
      <main>
        <div className="back">
          <div id="details" className="clearfix">
            <div id="coupon">
              <div className="gift">Gift Code</div>
              <div className="code">{data.gift_code}</div>
              <div id="company">
                <img src={data.logo} alt="Company Logo" />
              </div>
            </div>

            <div id="right">
              <div className="cvv">
                <h1>EXPIRY DATE : {data.expiry_date}</h1>
                <h1>CVV : {data.cvv}</h1>
              </div>
            </div>
          </div>

          <div id="messagediv" className="clearfix">
            <div id="text">
              <div className="message">
                {data.message}
              </div>
            </div>

            <div id="rightcoupon">
              <div className="value">₹{data.value}/-</div>
              <div id="gift">
                <img 
                  src="https://firebasestorage.googleapis.com/v0/b/sycamore-admin-6ca26.appspot.com/o/images%2Fbirthday%20wishes%20sartorius-02.png?alt=media&token=36889697-68a5-4623-a797-80a9ac6aa6bc" 
                  alt="Gift Image" 
                />
              </div>
              <div className="link">
                <a href="https://sartorius.luova-gifting.com/">
                  <span>To redeem your Gift, Click here </span>
                  <img 
                    src="https://firebasestorage.googleapis.com/v0/b/sycamore-admin-6ca26.appspot.com/o/images%2Fsmiley.png?alt=media&token=cd6d77f3-6416-495f-a4fb-44ef2695cbdd" 
                    alt="Smiley"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div className="footer">
        <div id="logo">
          <div className="back1">
            <img 
              src="https://firebasestorage.googleapis.com/v0/b/sycamore-admin-6ca26.appspot.com/o/images%2Flogo.png?alt=media&token=cbe0e3ee-9de0-43c9-acc1-5cb9f5a247d6" 
              alt="Logo" 
            />
            <div id="instruction">
              <div className="instructionTitle">Instructions to redeem</div>
              <ol>
                <li>Scratch the silver strip gently to view your Secure Gift Code</li>
                <li>Log on to <span style={{ color:"red"}}>{data.hyperlink}</span> <a href={data.user_name}> </a></li>
                <li>Enter your Gift Code</li>
                <li>Choose your gift voucher</li>
              </ol>
            </div>
          </div>
        </div>

        <div className="back2">
          <div id="contact">
            <div className="title">Luovagift has great customer services</div>
            <div className="call">Call Us : +91 8296099974 </div>
            <div className="mail">Mail Us : info@maalola.com </div>
            <span  className="mail2">customercare@maalola.com</span>
            {/* <div className="mail2">{"  "}customercare@maalola.com </div> */}
          </div>
        </div>
      </div>


  </div>
    </div>


));

const TemplatePrint = ({ data }) => {
  const componentRef = useRef();

  // Getting the print function from useReactToPrint
  const handlePrint = useReactToPrint({
    content: () => componentRef.current, // This should return the node to print
    documentTitle: data?.personal_details?.full_name || "default",
    onBeforePrint: () => console.log("Before printing..."),
    onAfterPrint: () => console.log("After printing..."),
  });

  return (
    <div>
      <Button
        onClick={handlePrint} // Calling the returned function directly
        type="primary"
        style={{ marginLeft: "84%",marginBottom:"10%", backgroundColor: "#43468B", color: "white" }}
      >
        Download PDF
      </Button>

      <div ref={componentRef}>
        <PrintComponent data={data} />
      </div>
    </div>
  );
};

export default TemplatePrint;



