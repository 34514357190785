import React, { useState } from "react";
import { Table, Space, Image, Button, Modal } from "antd";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { FaRegTrashAlt, FaRegEdit } from "react-icons/fa";
import { useDispatch } from "react-redux";
import DeleteConfirm from "../../shared/deleteConfirm";
import moment from "moment";
import { deleteTemplate } from "../../api/template";
import Templatedownload from "./templatepint"

export default function Datatable({ data, loading }) {
  const dispatch = useDispatch();
  const [item, setItem] = useState(null); // To store the clicked row data
  const [isModalOpen, setIsModalOpen] = useState(false); // To control modal visibility

  // Function to open modal with specific record's data
  const showModal = (record) => {
    setItem(record);  // Set the clicked row data into the state
    setIsModalOpen(true);  // Open the modal
  };

  // Handle the "OK" button of the modal
  const handleOk = () => {
    setIsModalOpen(false);  // Close the modal
  };

  // Handle the "Cancel" button of the modal
  const handleCancel = () => {
    setIsModalOpen(false);  // Close the modal
  };

  const confirm = (e, id) => {
    dispatch(deleteTemplate(id));  // Delete template by ID
  };

  const columns = [
    {
      title: "Username",
      dataIndex: "user_name",
      render: (name) => <h6>{name}</h6>,
    },
    {
      title: "Gift Code",
      dataIndex: "gift_code",
      render: (name) => <h6>{name}</h6>,
    },
    {
      title: "CVV",
      dataIndex: "cvv",
      render: (name) => <h6>{name}</h6>,
    },
    {
      title: "Value",
      dataIndex: "value",
      render: (name) => <h6>{name}</h6>,
    },
    {
      title: "Logo",
      dataIndex: "logo",
      render: (imag) => (
        <Image
          preview={false}
          style={{
            margin: "0px",
            width: "50px",
            borderRadius: "0.25rem",
            display: "block",
            objectFit: "cover",
          }}
          src={imag}
          className="bg-dark"
        />
      ),
    },
    {
      title: "Expiry Date",
      dataIndex: "expiry_date",
      render: (expiry_date) => <h6>{moment(expiry_date).format("MMM DD YYYY")}</h6>,
    },
    {
      title: "Action",
      render: (record) => (
        <Space size="middle">
          <h5>
            <Link to={`/admin/template/${record._id}`}>
              <FaRegEdit />
            </Link>
          </h5>
          <h5 className="text-danger">
            <DeleteConfirm confirm={(e) => confirm(e, record._id)} title="blog">
              <FaRegTrashAlt style={{ cursor: "pointer" }} />
            </DeleteConfirm>
          </h5>
          {/* Button to open modal for the specific record */}
          <Button type="primary" onClick={() => showModal(record)}>
            View Details
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <SDataModelWrap>
        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
          style={{ overflowY: "auto", height: "77vh", overflowX: "hidden" }}
          rowKey={(record) => record._id}
        />
      </SDataModelWrap>

      {/* Modal Component */}
      <Modal
        title="Template Details"
        visible={isModalOpen}  // Control visibility using state
        onOk={handleOk}  // Handle "OK" button click
        onCancel={handleCancel}
        footer={null} 
        width={1000}
        bodyStyle={{ maxHeight: '400px', overflowY: 'auto' }} // Handle "Cancel" button click
      >
        {/* Render the details of the selected row */}
        {item && (
          <>
          
            <Templatedownload data={item}/>
            
          </>
        )}
      </Modal>
    </>
  );
}

const SDataModelWrap = styled.div`
  svg {
    font-size: 1.2rem;
    text-align: center;
    width:auto
  }
`;
