// import React, { useRef } from "react";
// import { useReactToPrint } from "react-to-print";

// const PrintComponent = React.forwardRef(({ data }, ref) => (
//   <div ref={ref}>
//     <h1>Printable Content</h1>
//     <p>Full Name: {data?.personal_details?.full_name || "No Data"}</p>
//     <p>Email: {data?.personal_details?.email || "No Data"}</p>
//   </div>
// ));

// const TemplatePrint = ({ data }) => {
//   const componentRef = useRef();
//  console.log(componentRef.current)
//   const handlePrint = useReactToPrint({
//     content: () => {
//       console.log("inside it is",componentRef.current); // Confirm ref is set
//       return componentRef.current;
//     },
//     documentTitle: data?.personal_details?.full_name || "default",
//     onBeforePrint: () => console.log("Before printing...", "the content is",componentRef.current),
//     onAfterPrint: () => console.log("After printing..."),
//   });

//   return (
//     <div>
//       <button
//         onClick={handlePrint}
//         className="ml-[84%] bg-[#43468B] text-white text-center flex border px-4 py-2 rounded-md mb-2"
//       >
//         Download PDF
//       </button>
//       <div ref={componentRef}>
//         <PrintComponent data={data} />
//       </div>
//     </div>
//   );
// };

// export default TemplatePrint;

// --------------------------------second trail-----------------------------------
// import React, { useRef } from "react";
// import { useReactToPrint } from "react-to-print";


// const PrintComponent = React.forwardRef(({ data }, ref) => (
//   <div ref={ref}>
//     <h1>Printable Content</h1>
//     <p>Full Name: {data?.personal_details?.full_name || "No Data"}</p>
//     <p>Email: {data?.personal_details?.email || "No Data"}</p>
//   </div>
// ));

// const TemplatePrint = ({ data }) => {
//   const componentRef = useRef();

 
//   const handlePrint = useReactToPrint({
//     contentRef: () => componentRef.current,
//     documentTitle: data?.personal_details?.full_name || "default",
//     onBeforePrint: () => console.log("before printing..."),
//     onAfterPrint: () => console.log("after printing..."),
//     removeAfterPrint: true,
//   });

//   const handleButtonClick = () => {
//     console.log("Button clicked");
//     console.log("componentRef.current:", componentRef.current); 
//     if (componentRef.current) {
//       handlePrint();  
//     } else {
//       console.log("No content to print, ref is null or undefined.");
//     }
//   };

//   return (
//     <div>
//       <button
//         onClick={handleButtonClick}
//         className="ml-[84%] bg-[#43468B] text-white text-center flex border px-4 py-2 rounded-md mb-2"
//       >
//         Download PDF
//       </button>

//       <div ref={componentRef}>
//         <PrintComponent data={data} />
//       </div>
//     </div>
//   );
// };

// export default TemplatePrint;



// ---------------------------------------third trail----------------------------------------
// import React, { useRef } from "react";
// import { useReactToPrint } from "react-to-print";
// import { Button } from "antd"; // AntD Button component
// // import Banner from "../../shared/images/logo2.png"
// const today = new Date();
// const t = data.scouponsOrder
// .map((item, i) => {

//     return `<tr class="item" key=${i}>
//       <td class="no">${i+1}</td>
//       <td class="desc"><h3> ${item.name}</h3></td>
//       <td class="unit">&#x20B9 ${item.value}</td>
//       <td class="qty"> ${item.quantity}</td>
//       <td class="total">&#x20B9 ${item.value * item.quantity}</td>
//     </tr>`
//   })
// // PrintComponent to be printed
// const PrintComponent = React.forwardRef(({ data }, ref) => (

// //   <div ref={ref} style={{ fontFamily: "Poppins, sans-serif", fontSize: "8px", letterSpacing: "1px" }}>
// <div ref={ref}
// style={{
//   maxWidth: '20cm',
//   border: '1px solid #eee',
//   padding: '5px 25px',
//   position: 'relative',
//   height: '28.9cm',
//   margin: '0 auto',
//   color: 'black',
//   background: '#FFFFFF',
//   fontFamily: 'Arial, sans-serif',
//   fontSize: '16px'
// }}
// >
//     { console.log(data)}
// <header style={{ padding: '10px 0', marginBottom: '20px', borderBottom: '1px solid #6E6D6D' }}>
//   <div style={{ float: 'left', marginTop: '8px' }}>
//     <img
//       src="https://i2.wp.com/cleverlogos.co/wp-content/uploads/2018/05/reciepthound_1.jpg?fit=800%2C600&ssl=1"
//       alt="Logo"
//       style={{ height: '70px' }}
//     />
//   </div>
//   <div style={{ textAlign: 'center' }}>
//     <h2 style={{ color: '#003EC3', fontSize: '1.8em', margin: '0', alignItems:"center" }}>SYCAMORE</h2>
//     <div>Foggy Heights, AZ 85004, US</div>
//     <div>(602) 519-0450</div>
//     <div >sycamore@example.com</div>
//   </div>
// </header>
// <main>
//   <div style={{ marginBottom: '50px', display: 'flex', justifyContent: 'space-between' }}>
//     <div
//       style={{
//         paddingLeft: '6px',
//         paddingBottom: '5px',
//         borderLeft: '6px solid #038AC4',
//         width: '250px'
//       }}
//     >
//       <div style={{ color: '#000000' }}>INVOICE TO:</div>
//       <h2 style={{ fontSize: '1.2em', fontWeight: 'normal', margin: '0' }}>{data.user.email} </h2>
//       <div>{data.info}</div>
//     </div>
//     <div style={{ textAlign: 'right' }}>
//       <div style={{ fontSize: '1.2em' }}>
//         Date: {`${today.getDate()}. ${today.getMonth() + 1}. ${today.getFullYear()}.`}
//       </div>
//       <h1 style={{ fontSize: '1em', lineHeight: '1em', fontWeight: 'normal', margin: '10px 0 0 0' }}>
//         Invoice Number: {data.invoiceNumber}
//       </h1>
//       <h1 style={{ fontSize: '1em', lineHeight: '1em', fontWeight: 'normal', margin: '10px 0 0 0' }}>
//         Order ID: {data.sycOrderId
//         }
//       </h1>
//     </div>
//   </div>
//   <table
//     style={{ width: '100%', borderCollapse: 'collapse', borderSpacing: '0', marginBottom: '20px' }}
//   >
//     <thead>
//       <tr>
//         <th style={{ padding: '12px', background: '#D4E7F1', fontWeight: 'bold', fontSize: '1em', borderBottom: '1px solid #003091' }}>
//           No.
//         </th>
//         <th style={{ padding: '12px', background: '#D4E7F1', fontWeight: 'bold', fontSize: '1em', borderBottom: '1px solid #003091', textAlign: 'left' }}>
//           DESCRIPTION
//         </th>
//         <th style={{ padding: '12px', background: '#D4E7F1', fontWeight: 'bold', fontSize: '1em', borderBottom: '1px solid #003091' }}>
//           UNIT PRICE
//         </th>
//         <th style={{ padding: '12px', background: '#D4E7F1', fontWeight: 'bold', fontSize: '1em', borderBottom: '1px solid #003091' }}>
//           QUANTITY
//         </th>
//         <th style={{ padding: '12px', background: '#D4E7F1', fontWeight: 'bold', fontSize: '1em', borderBottom: '1px solid #003091' }}>
//           TOTAL
//         </th>
//       </tr>
//     </thead>
//     {}
//     <tbody>{t}</tbody>
//     <tfoot>
//       <tr>
//         <td colSpan="2"></td>
//         <td colSpan="2">SUBTOTAL</td>
//         <td>&#x20B9; {data.total}</td>
//       </tr>
//       <tr>
//         <td colSpan="2"></td>
//         <td colSpan="2">GST</td>
//         <td>&#x20B9; 00.00</td>
//       </tr>
//       <tr>
//         <td colSpan="2"></td>
//         <td colSpan="2">GRAND TOTAL</td>
//         <td>&#x20B9; {data.total} /-</td>
//       </tr>
//     </tfoot>
//   </table>
//   <div style={{ fontSize: '2em', marginBottom: '50px' }}>Thank you!</div>
//   <div
//     style={{
//       paddingLeft: '6px',
//       borderLeft: '6px solid #0087C3'
//     }}
//   >
//     <div>NOTICE:</div>
//     <div style={{ fontSize: '1em' }}>
//       A finance charge of 1.5% will be made on unpaid balances after 30 days.
//     </div>
//   </div>
// </main>
// <footer
//   style={{
//     color: '#777777',
//     width: '100%',
//     height: '30px',
//     position: 'absolute',
//     bottom: '0',
//     borderTop: '1px solid #AAAAAA',
//     padding: '8px 0px',
//     textAlign: 'center',
//     maxWidth: '20cm'
//   }}
// >
//   Invoice was created on a computer and is valid without the signature and seal.
// </footer>
// </div>


// ));

// const TemplatePrint = ({ data }) => {
//   const componentRef = useRef();

//   // Getting the print function from useReactToPrint
//   const handlePrint = useReactToPrint({
//     content: () => componentRef.current, // This should return the node to print
//     documentTitle: data?.personal_details?.full_name || "default",
//     onBeforePrint: () => console.log("Before printing..."),
//     onAfterPrint: () => console.log("After printing..."),
//   });

//   return (
//     <div>
//       <Button
//         onClick={handlePrint} // Calling the returned function directly
//         type="primary"
//         style={{ marginLeft: "84%",marginBottom:"10%", backgroundColor: "#43468B", color: "white" }}
//       >
//         Download PDF
//       </Button>

//       <div ref={componentRef}>
//         <PrintComponent data={data} />
//       </div>
//     </div>
//   );
// };

// export default TemplatePrint;


import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Button } from "antd"; // AntD Button component

// PrintComponent to be printed
const PrintComponent = React.forwardRef(({ data }, ref) => {
  const today = new Date();

  const tableRows = data.scouponsOrder.map((item, i) => (
    <tr className="item" key={i}>
      <td className="no" style={styless.td}>{i + 1}</td>
      <td className="desc" style={styless.td}>
        <span>{item.name}</span>
      </td>
      <td className="unit" style={styless.td}>&#x20B9; {item.value}</td>
      <td className="qty" style={styless.td}>{item.quantity}</td>
      <td className="total" style={styless.td}>&#x20B9; {item.value * item.quantity}</td>
    </tr>
  ));

  return (
    <div
      ref={ref}
      style={{
        maxWidth: "20cm",
        border: "1px solid #eee",
        padding: "5px 25px",
        position: "relative",
        height: "28.9cm",
        width:"80%",
        margin: "0 auto",
        marginTop:"5rem",
        color: "black",
        background: "#FFFFFF",
        fontFamily: "Arial, sans-serif",
        fontSize: "16px",
        
      }}
    >
      {console.log(data)}
      <header style={{ padding: "10px 0", marginBottom: "20px", borderBottom: "1px solid #6E6D6D" }}>
        <div style={{ float: "left", marginTop: "8px" }}>
          <img
            src="https://i2.wp.com/cleverlogos.co/wp-content/uploads/2018/05/reciepthound_1.jpg?fit=800%2C600&ssl=1"
            alt="Logo"
            style={{ height: "70px" }}
          />
        </div>
        <div style={{ textAlign: "center" }}>
          <h2 style={{ color: "#003EC3", fontSize: "1.8em", margin: "0", alignItems: "center" }}>
            SYCAMORE
          </h2>
          <div>Foggy Heights, AZ 85004, US</div>
          <div>(602) 519-0450</div>
          <div style={{alignItems:"center"}}><span style={{alignItems:"center", marginLeft:"5rem"}}>sycamore@example.com</span></div>
        </div>
      </header>
      <main>
        <div
          style={{ marginBottom: "50px", display: "flex", justifyContent: "space-between" }}
        >
          <div
            style={{
              paddingLeft: "6px",
              paddingBottom: "5px",
              borderLeft: "6px solid #038AC4",
              width: "250px",
            }}
          >
            <div style={{ color: "#000000" }}>INVOICE TO:</div>
            <h2
              style={{ fontSize: "1.2em", fontWeight: "normal", margin: "0" }}
            >
             {data.user.address},{data.address}
            </h2>
            <div>{data.info}</div>
          </div>
          <div style={{ textAlign: "right" }}>
            <div style={{ fontSize: "1.2em" }}>
              Date: {`${today.getDate()}. ${today.getMonth() + 1}. ${today.getFullYear()}.`}
            </div>
            <h1
              style={{ fontSize: "1em", lineHeight: "1em", fontWeight: "normal", margin: "10px 0 0 0" }}
            >
              Invoice Number: {data.invoiceNumber}
            </h1>
            <h1
              style={{ fontSize: "1em", lineHeight: "1em", fontWeight: "normal", margin: "10px 0 0 0" }}
            >
              Order ID: {data.sycOrderId}
            </h1>
          </div>
        </div>
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            borderSpacing: "0",
            marginBottom: "20px",
          }}
        >
          <thead>
            <tr>
              <th style={styless.th}>No.</th>
              <th style={{ ...headerStyle, textAlign: "left",borderRight: '1px solid #FFFFFF' }}>DESCRIPTION</th>
              <th style={styless.th}>UNIT PRICE</th>
              <th style={headerStyle}>QUANTITY</th>
              <th style={headerStyle}>TOTAL</th>
            </tr>
          </thead>
          <tbody style={styless}>{tableRows}</tbody>
          <tfoot>
            <tr style={footstyle.tr}>
              <td colSpan="2" style={footstyle.firstChildTd} ></td>
              <td colSpan="2"  style={footstyle.firstChildTd}>SUBTOTAL</td>
              <td  style={footstyle.firstChildTd}>&#x20B9; {data.total}</td>
              <hr style={hrstyle}></hr>
            </tr>
            
            <tr>
                
              <td colSpan="2"></td>
              <td colSpan="2">GST</td>
              <td>&#x20B9; 00.00</td>
              <hr></hr>
            </tr>
            <tr>
              <td colSpan="2"></td>
              <td colSpan="2">GRAND TOTAL</td>
              <td>&#x20B9; {data.total} /-</td>
              <hr ></hr>
            </tr>
          </tfoot>
        </table>
        <div style={{ fontSize: "2em", marginBottom: "50px" }}>Thank you!</div>
        <div
          style={{
            paddingLeft: "6px",
            borderLeft: "6px solid #0087C3",
          }}
        >
          <div>NOTICE:</div>
          <div style={{ fontSize: "1em" }}>
            A finance charge of 1.5% will be made on unpaid balances after 30 days.
          </div>
        </div>
      </main>
      <footer
        style={{
          color: "#777777",
          width: "100%",
          height: "30px",
          position: "absolute",
          bottom: "8px",
          left:"0",
          borderTop: "1px solid #AAAAAA",
          padding: "8px 0px",
          textAlign: "center",
        //   maxWidth: "20cm",
        }}
      >
        Invoice was created on a computer and is valid without the signature and seal.
      </footer>
    </div>
  );
});

const headerStyle = {
    whiteSpace: "nowrap",        
    fontWeight: "bold",
    background: "#D4E7F1",
    fontSize: "1em",
    borderBottom: "1px solid #003091",
    margin:"5px",
    padding:"8px"
  };
  const styless = {
    td: {
      padding: '2px',
      background: '#EEEEEE',
      textAlign: 'center',
      borderBottom: '1px solid #FFFFFF',
      borderRight: '1px solid #FFFFFF'
    },
    th: {
        whiteSpace: 'nowrap', 
        fontWeight: 'bold', 
        background: '#D4E7F1', 
        textAlign: 'center',
        fontSize: '1em', 
        borderRight: '1px solid #FFFFFF',
        borderBottom: '1px solid #003091' 
    },
    // Additional styles can be added similarly
  };
  const footstyle={
    td: {
        padding: '10px 20px',
        background: '#FFFFFF',
        borderBottom: 'none',
        fontSize: '1.1em',
        whiteSpace: 'nowrap',
        borderTop: '1px solid #AAAAAA',
      },
      firstRowTd: {
        borderTop: 'none',
      },
      lastRowTd: {
        fontWeight: 'bold',
        fontSize: '1.1em',
      },
      firstChildTd: {
        border: 'none',
      },
  }
const hrstyle={
   border: "none",
   
}

const TemplatePrint = ({ data }) => {
  const componentRef = useRef();

  // Getting the print function from useReactToPrint
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `${data?.user?.user_name}'s Invoice` || "default",
    onBeforePrint: () => console.log("Before printing..."),
    onAfterPrint: () => console.log("After printing..."),
  });

  return (
    <div>
      <Button
        onClick={handlePrint}
        type="primary"
        style={{
          marginLeft: "84%",
          marginBottom: "10%",
          backgroundColor: "#43468B",
          color: "white",
        }}
      >
        Download PDF
      </Button>

      <div ref={componentRef}>
        <PrintComponent data={data} />
      </div>
    </div>
  );
};

export default TemplatePrint;




