// import React,{useState} from 'react'
// import {Table, Space,Button } from 'antd'
// import styled from 'styled-components'
// import {Link} from 'react-router-dom'
// import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa';
// import {useDispatch, useSelector} from 'react-redux'
// import {createProductOrderPdf, couponsSelector} from '../../../api/productSlice'
// import Invoicetemplate from "./"

// export default function Datatable({data,loading}) {


//     const dispatch = useDispatch()
//     const [loadings,setLoading] =useState(false)
//     const [item,setItem] =useState(null)

//     // dispatch(createProductOrderPdf(value))

  
//     const handlechange = (value) =>{
//         dispatch(createProductOrderPdf(value))
//         setLoading(true)
//             setItem(value._id)
//         setTimeout(()=>{
//             setLoading(false)
//             setItem(null)
//         },3000)
//         }

//     const columns = [
//         {
//             title:"Name",
//             render: (value)=>{

//                 if(value.user){

//                 return <p>{value.user.email}</p>
                  
//                 }
                
//                if(value.existUser) {
                    
//                   return   <p>{value.existUser.email}</p>

//                 }

//                 // value && value.user ? value.user.email : value.existUser.email
//                 return null
//             }
            
            
//         },

//         {
//             title:"Order ID",
//             dataIndex:"sycOrderId",

//         },
//         {
//             title:"Razorpay Order ID",
//             dataIndex:"orderId",

//         },
        
//         {
//             title:'Invoice',
//             render: (value)=>  
//             <Button type="primary" onClick={()=>handlechange(value)}>
//                {(loadings && item === value._id) ? 'Loading': 'Download'}
//             </Button>
//         }
//     ]

//     return (
//         <SDataModelWrap>
//              <Table
//              loading={loading} 
//              dataSource={data}
//             columns={columns}
//             style={{overflowY:'auto', height:'79vh', overflowX:'hidden' }}

//             rowKey = {(record => record._id)}
//             expandable={{
//                 expandedRowRender: record => {
                
                   
//                     return <div >   
                
    
  
//       <div class=" text-left" >
//       <div class="row">
//       <div class="col-2 ">
//       <p><b className="pl-4">Sl.No</b></p>
//       </div>
//       <div class="col-2">
//       <p><b className="">Item</b></p>
//       </div>
//       <div class="col-2">
//       <p className=""><b>Quantity</b></p>
//       </div>
//       <div class="col-2">
//       <p className=""><b>Unit Price</b></p>
//       </div>
//       <div class="col-2">
//       <p className=""><b>Total Price : {record.total }</b></p>
//       </div>
//       </div>
//       </div>
      
//       {
//           record?.products?.map((item,i)=>{
//            return <div key={i} class=" text-left">
//             <div class="row">
//             <div class="col-2">
//             <p className=" pl-4">{i+1}</p>
//             </div>
//             <div class="col-2">
//             <p className="">{item.name}</p>
//             </div>
//             <div class="col-2">
//             <p className="">{item.qty}</p>
//             </div>
//             <div class="col-2">
//             <p className="">{item.value }</p>
//             </div>
          
//             </div>
//             </div>


//           })
//       }

//   </div>    
  
  
//                 },
//                 rowExpandable: record => record.user  !== null,
//               }}
         

//             /> 
         
//         </SDataModelWrap>
//     )
// }


// const SDataModelWrap = styled.div`

// `

import React, { useState } from 'react';
import { Table, Space, Button, Modal } from 'antd';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { createProductOrderPdf } from '../../../api/productSlice';
import Invoice from "../productOrder/invoiceprint"

export default function Datatable({ data, loading }) {
  const dispatch = useDispatch();
  const [loadings, setLoading] = useState(false);
  const [item, setItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalItem, setModalItem] = useState(null);

  // Handle PDF creation
  const handlePdfDownload = (value) => {
    dispatch(createProductOrderPdf(value));
    setLoading(true);
    setItem(value._id);
    setTimeout(() => {
      setLoading(false);
      setItem(null);
    }, 3000);
  };

  // Handle Modal visibility
  const showModal = (record) => {
    setModalItem(record); // Set the clicked row data
    setIsModalOpen(true); // Open the modal
  };

  const handleCancel = () => {
    setIsModalOpen(false); // Close the modal
  };

  const columns = [
    {
      title: 'Name',
      render: (value) => {
        if (value.user) {
          return <p>{value.user.email}</p>;
        }
        if (value.existUser) {
          return <p>{value.existUser.email}</p>;
        }
        return null;
      },
    },
    {
      title: 'Order ID',
      dataIndex: 'sycOrderId',
    },
    {
      title: 'Razorpay Order ID',
      dataIndex: 'orderId',
    },
    {
      title: 'Actions',
      render: (value) => (
        <Space>
          <Button type="default" onClick={() => showModal(value)}>
            View Details
          </Button>
          <Button type="primary" onClick={() => handlePdfDownload(value)}>
            {loadings && item === value._id ? 'Loading...' : 'Download'}
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <SDataModelWrap>
      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        style={{ overflowY: 'auto', height: '79vh', overflowX: 'hidden' }}
        rowKey={(record) => record._id}
        expandable={{
          expandedRowRender: (record) => (
            <div>
              <div className="text-left">
                <div className="row">
                  <div className="col-2">
                    <p>
                      <b className="pl-4">Sl.No</b>
                    </p>
                  </div>
                  <div className="col-2">
                    <p>
                      <b>Item</b>
                    </p>
                  </div>
                  <div className="col-2">
                    <p>
                      <b>Quantity</b>
                    </p>
                  </div>
                  <div className="col-2">
                    <p>
                      <b>Unit Price</b>
                    </p>
                  </div>
                  <div className="col-2">
                    <p>
                      <b>Total Price: {record.total}</b>
                    </p>
                  </div>
                </div>
              </div>

              {record?.products?.map((item, i) => (
                <div key={i} className="text-left">
                  <div className="row">
                    <div className="col-2">
                      <p className="pl-4">{i + 1}</p>
                    </div>
                    <div className="col-2">
                      <p>{item.name}</p>
                    </div>
                    <div className="col-2">
                      <p>{item.qty}</p>
                    </div>
                    <div className="col-2">
                      <p>{item.value}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ),
          rowExpandable: (record) => record.user !== null,
        }}
      />

      {/* Modal for Order Details */}
      <Modal
        title="Order Details"
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={800}
        bodyStyle={{ maxHeight: '400px', overflowY: 'auto' }}
      >
        {modalItem && (
          <div>
            <p><b>Order ID:</b> {modalItem.sycOrderId}</p>
            <p><b>User Email:</b> {modalItem.user?.email || modalItem.existUser?.email}</p>
            <p><b>Total:</b> {modalItem.total}</p>
            <div>
              <h4>Products:</h4>
              {modalItem.products.map((product, index) => (
                <div key={index}>
                  <p>
                    {index + 1}. <b>Name:</b> {product.name}, <b>Quantity:</b>{' '}
                    {product.qty}, <b>Price:</b> {product.value}
                  </p>
                </div>
              ))}
            </div>
            <Invoice data={modalItem}/>
          </div>
        )}
      </Modal>
    </SDataModelWrap>
  );
}

const SDataModelWrap = styled.div`
  svg {
    font-size: 1.2rem;
    text-align: center;
  }
  .ant-descriptions-title {
    padding-left: 2rem;
    font-size: 1rem;
    color: grey;
  }
`;
