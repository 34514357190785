import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Button } from "antd";
import "../productOrder/Invoice.css";
// import { width } from "pdfkit/js/page";
// AntD Button component
// import { Refresh } from "@mui/icons-material";

// PrintComponent to be printed
const PrintComponent = React.forwardRef(({ data }, ref) => {
  const today = new Date();
  console.log("here it is fkin data", data.products);
  const t = data.products.map((item, i) => (
    <tr key={i}>
      <td style={{ padding: "12px", textAlign: "center" }}>{i + 1}</td>
      <td style={{ padding: "12px", textAlign: "center" }}>
        <h6 style={{ marginTop: "8px" }}>{item.name}</h6>
      </td>
      <td style={{ padding: "12px", textAlign: "center" }}>
        &#x20B9; {item.value}
      </td>
      <td style={{ padding: "12px", textAlign: "center" }}>{item.qty}</td>
      <td style={{ padding: "12px", textAlign: "center" }}>
        &#x20B9; {item.value * item.qty}
      </td>
    </tr>
  ));

  const styles = {
    body: {
      maxWidth: "20cm",
      border: "1px solid #eee",
      padding: "5px 25px",
      position: "relative",
      height: "29.3cm",
      margin: "0 auto",
      color: "black",
      background: "#FFFFFF",
      fontFamily: "Arial, sans-serif",
      fontSize: "16px",
    },
    clearfix: {
      clear: "both",
    },
    logoBg: {
      position: "absolute",
      left: "0px",
      background: "#2e2e2e",
      width: "13cm",
      height: "3cm",
      top: "0",
      borderBottomRightRadius: "500px",
    },
    logo: {
      position: "absolute",
      left: "0px",
      background: "#ededed",
      width: "12.5cm",
      height: "3cm",
      borderBottomRightRadius: "500px",
    },
    logoImg: {
      position: "absolute",
      margin: "5px 0 0 10px",
      float: "left",
      height: "70px",
    },
    invoice: {
      position: "absolute",
      right: "19px",

      paddingBottom: "15px",
    },
    invoiceHeader: {
      color: "#fcb90d",
      lineHeight: "1em",
      margin: "55px 60px 0 0",
    },
    main: {
      position: "relative",
      padding: "150px 0 0 0",
    },
    flexContainer: {
      display: "flex",
      width: "100%",

      justifyContent: "space-between",
    },
    title: {
      color: "#FCB90D",
      paddingBottom: "5px",
      fontWeight: 600, // Semibold text weight
    },
    titles: {
      color: "#FCB90D",
      paddingBottom: "5px",
      fontWeight: 600,
      marginTop: "40px",
    },
    client: {
      // color:'#FCB90D',
      marginTop: "125px",
      paddingLeft: "6px",
      paddingBottom: "15px",
      float: "left",
      width: "400px",
      fontSize: "14px",
    },
    headerfive: {
      color: "#FCB90D",
    },

    payment: {
      width: "full",
      float: "right",
      margin: "30px 0 40px 0",
      marginleft: "15px",
    },
    table: {
      width: "100%",
      borderCollapse: "collapse",
      borderSpacing: "0",
    },
    th: {
      padding: "12px",
      textAlign: "center",
      whiteSpace: "nowrap",
      fontWeight: "normal",
      background: "#696161",
      fontWeight: "bold",
      fontSize: "0.9em",
      color: "#ffffff",
    },
    td: {
      padding: "12px",
      textAlign: "right",
    },
    tfoot: {
      padding: "10px 20px",
      background: "#FFFFFF",
      borderBottom: "none",
      fontSize: "1.1em",
      whiteSpace: "nowrap",
      borderTop: "1px solid #AAAAAA",
      marginTop: "25px",
    },
    footer: {
      background: "#1a1515",
      margin: "0 auto",
      color: "#ffffff",
      width: "18cm",
      height: "1.8cm",
      position: "absolute",
      bottom: "0",
      left: "0",
      padding: "8px 0 8px 0",
      borderTopRightRadius: "500px",
    },
    span: {
      margin: "2px",
      marginLeft: "15px", // Corrected to camelCase
    },
    footerBg: {
      position: "absolute",
      background: "#fcb90d",
      margin: "0 auto",
      width: "8.8cm",
      height: "0.5cm",
      bottom: "0",
      right: "0",
      padding: "8px 0 0 0",
      borderTopLeftRadius: "100px",
    },
  };

  return (
    <div ref={ref} style={styles.body}>
      <header style={styles.clearfix}>
        <div style={styles.logoBg}>
          <div style={styles.logo}>
            <img
              src="https://i2.wp.com/cleverlogos.co/wp-content/uploads/2018/05/reciepthound_1.jpg?fit=800%2C600&ssl=1"
              style={styles.logoImg}
              alt="Logo"
            />
          </div>
        </div>
      </header>

      <div style={styles.invoice}>
        <h1 style={styles.invoiceHeader}>INVOICE</h1>
        <div>Invoice ID: {data.invoiceNumber}</div>
        <div>Order ID: {data.sycOrderId} </div>
        <div>
          Invoice Date:{" "}
          {`${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}.`}
        </div>
      </div>

      <main style={styles.main}>
        <div style={styles.flexContainer}>
          <div style={styles.client}>
            <div className="title"></div>
            <h2 className="name"> {data.user.email} </h2>
            <div>
              {" "}
              {data.user.user_name},{data.address}{" "}
            </div>
          </div>
          {console.log(data)}
          <div style={styles.payment}>
            <h5 style={styles.title}>Payment Method</h5>
            <span>Account No: {data.invoiceNumber}</span>
            <br></br>
            <span>Account Name: {data.invoiceNumber} </span>
          </div>
        </div>

        <div>
          <table
            style={styles.table}
            border="0"
            cellspacing="0"
            cellpadding="0"
          >
            <thead>
              <tr>
                <th style={styles.th} className="no">
                  No.
                </th>
                <th style={styles.th} className="desc">
                  ITEM DESCRIPTION
                </th>
                <th style={styles.th}>PRICE</th>
                <th style={styles.th}>QTY.</th>
                <th style={styles.th}>TOTAL</th>
              </tr>
            </thead>
            <tbody>{t}</tbody>
            <tfoot style={styles.tfoot}>
              <br></br>
              <br></br>
              <br></br>
              <tr>
                <td colspan="2"></td>
                <td colspan="2">SUBTOTAL</td>
                <td>&#x20B9; {data.total}</td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="2">GST</td>
                <td>&#x20B9; 00.00</td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="2">GRAND TOTAL</td>
                <td>&#x20B9; {data.total} /-</td>
              </tr>
            </tfoot>
          </table>
        </div>

        <div style={styles.flexContainer}>
          <div style={styles.client}>
            <span style={styles.titles}>Terms & Conditions:</span>
            <div>
              A finance charge of 1.5% will be made on unpaid balances after 30
              days.
            </div>
            <div>
              Invoice was created on a computer and is valid without the
              signature and seal.
            </div>
            <h4>Thank you for business with us.</h4>
          </div>
        </div>
      </main>

      <footer style={styles.footer}>
        <span style={styles.span}>
          Invoice was created on a computer and is valid without the signature
          and seal.
        </span>
      </footer>
      <div style={styles.footerBg}></div>
    </div>
  );
});

const TemplatePrint = ({ data }) => {
  const componentRef = useRef();

  // Getting the print function from useReactToPrint
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `${data?.user?.user_name}'s Invoice` || "default",
    onBeforePrint: () => console.log("Before printing..."),
    onAfterPrint: () => console.log("After printing..."),
  });

  return (
    <div>
      <Button
        onClick={handlePrint}
        type="primary"
        style={{
          marginLeft: "84%",
          marginBottom: "10%",
          backgroundColor: "#43468B",
          color: "white",
        }}
      >
        Download PDF
      </Button>

      <div ref={componentRef}>
        <PrintComponent data={data} />
      </div>
    </div>
  );
};

export default TemplatePrint;
